import React, { useContext, useEffect } from 'react';
import { PageProps, Link, graphql } from 'gatsby';
import NewsNavi from '@/components/News/NewsNavi';
import SEO from '@/components/seo';
import CDNReplace from '@/components/CDNReplace';
import UILabel from '@/components/UI/UILabel';
import hoverCircle from '@/components/Interaction/hoverCircle';
import * as Util from '@/components/Util';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import { Context } from '@/components/Layout';

import * as styles from '@/styles/page/news.module.scss';
import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';

const NewsIndex: React.FC<PageProps<GatsbyTypes.NewsIndexQuery>> = (props) => {
  const { data } = props;
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  const latestYear = data.allMicrocmsNews.edges[0].node.year;
  const dispArticle = data.allMicrocmsNews.edges.filter(
    (x) => x.node.year === latestYear,
  );

  return (
    !loading && (
      <>
        <SEO title="ニュース" />
        <div className="page-wrap">
          <header className={pageHeaderCategory.page_header_category}>
            <h1>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                お知らせ
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                News
              </div>
            </h1>
            <p className={pageHeaderCategory.page_header_category__lead}>
              NRIネットコムに関するニュースをお届けします。
            </p>
          </header>
          <div className={`page-contents-area-01 ${styles.news_contents}`}>
            <div className={styles.news_contents__list}>
              {React.Children.toArray(
                dispArticle.map(({ node }) => (
                  <>
                    <article
                      key={node.newsId}
                      className={`${styles.news_link} --borderBottom}`}
                    >
                      {node.pdf ? (
                        <Link
                          to={CDNReplace(node.pdf.url)}
                          target="_blank"
                          className={`${styles.news_link__wrap} js-hover-circle`}
                          data-circle-inner="arrow-right"
                        >
                          <UILabel text={Util.newsLabel(node.category[0])} />
                          <time
                            dateTime={Util.formatDate(
                              new Date(node.publishedAt),
                            )}
                            className={styles.news_date}
                            style={{ marginLeft: `1.2rem` }}
                          >
                            {Util.formatDate(new Date(node.publishedAt))}
                          </time>
                          <p className={styles.news_link__title}>
                            {node.title}
                            <span className="tag-pdf">PDF</span>
                          </p>
                        </Link>
                      ) : (
                        <Link
                          to={`/news/${node.year}/${node.newsId}/`}
                          className={`${styles.news_link__wrap} js-hover-circle`}
                          data-circle-inner="arrow-right"
                        >
                          <UILabel text={Util.newsLabel(node.category[0])} />
                          <time
                            dateTime={Util.formatDate(
                              new Date(node.publishedAt),
                            )}
                            className={styles.news_date}
                            style={{ marginLeft: `1.2rem` }}
                          >
                            {Util.formatDate(new Date(node.publishedAt))}
                          </time>
                          <p className={styles.news_link__title}>
                            {node.title}
                          </p>
                        </Link>
                      )}
                    </article>
                  </>
                )),
              )}
            </div>
            <div className={styles.news_contents__side}>
              <div className={styles.news_side_box}>
                <NewsNavi CurrentYear={latestYear} />
              </div>
            </div>
          </div>
        </div>
        <UIBreadcrumb UpperItem={[]} CurrentItem="News" />
      </>
    )
  );
};

export default NewsIndex;

export const query = graphql`
  query NewsIndex {
    allMicrocmsNews(
      sort: { order: DESC, fields: publishedAt }
      filter: { newsId: { ne: "template" } }
    ) {
      edges {
        node {
          id
          category
          publishedAt(formatString: "YYYY/MM/DD")
          newsId
          title
          pdf {
            url
          }
          year: publishedAt(formatString: "YYYY")
        }
      }
    }
  }
`;
